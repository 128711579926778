import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/hris_blog_header.png");
const section_1 = require("../../../assets/img/blogs/hris_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/hris_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/hris_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/hris_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/hris_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/hris_blog_img_6.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="HRIS Employee Self-Service - Complete Guide - 2023"
        description="Use Conversational AI to personalize employee self-service (ESS) portals and drive real-world business outcomes. Enhance the employee experience now."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt hris_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                <h1 className="font-section-sub-header-blog-title color-white ">
                  A Complete Guide - 2023
                </h1>
                <h2 className="font-page-header-home-blog color-white">
                  HR Employee Self-Service
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <Faq /> : null}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Challenges of HR-employee support
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What is an HRIS employee self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. What can you do with ESS HRIS?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are the essential tools to maximize the use of the ESS
                  platform?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How to reduce employee self-service abandonment?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Benefits of employee self-service HRIS
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. What is Workativ's advantage?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Effective internal communication is integral to strengthening
                employee engagement and satisfaction. It helps build a positive
                work environment for employees wanting to work and thrive. Also,
                employee engagement is viewed as a lever to avoid the fear of
                attrition and drive employee loyalty. This is a critical aspect
                of operational resilience, where organizations must respect this
                outlook and provide a template for employees to engage and gain
                confidence in their actions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                An HRIS employee self-service system nurtures the growing demand
                of employers to make internal communication flexible and
                convenient for their employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                New findings by the University of Florida College of Journalism
                and Communications discovered that{" "}
                <a href="https://www.jou.ufl.edu/2022/12/14/study-internal-communication-can-help-strengthen-employee-engagement/">
                  internal communications strengthen employee organizational
                  engagement.
                </a>{" "}
                The study further emphasized the need to thoroughly understand
                employee attitudes to manage satisfaction and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To achieve this organizational objective, an employee
                self-service ESS portal can deliver a competitive advantage for
                organizations to work with data, enabling them to personalize
                employee experience and drive real-world business values.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s explore the significance of the human resource information
                system ESS here in this article.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Challenges of HR-employee support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                High-quality manager and employee support need HR leaders to
                prioritize real-time information delivery. But to deliver more
                effective and efficient HR-employee support, it is essential to
                rise above the challenges that bog down HR efficiency.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" challenges-employee-support"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                HR leaders need digital self-service and HRIS employee
                self-service, where employees can fetch critical and simple
                forms of information. But, service delivery and functional
                resilience are not always top-notch. Some strategic differences
                ensue and increase HR challenges. What are they?
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Too many contact channels for first-contact resolution
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                First-contact resolution is always desirable across every
                touchpoint. Just as the customer service best practice aims at
                resolving issues in just one call, workplace ethics are also no
                different. This tends to force companies to implement multiple
                channels such as voice calls, SMS, social media channels, and
                emails to raise issues.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="employee-support-channels"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                This is, however, linked to making things complicated and
                impacting FCR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The 2014-2019 Gartner Effortless Experience Survey shows that
                <a href="https://emtemp.gcom.cloud/ngw/globalassets/en/human-resources/documents/3-techniques-to-improve-selfservice-for-employees-support.pdf">
                  FCR declines with the number of channels a company adds.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a request is coming from a particular channel like a live
                chat; the same request may arrive through messaging feeds on
                Twitter or other social media channels, considering a customer
                being impatient. This makes establishing the context for the
                same issue complex, thus delaying the resolution time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contrary to this, a self-assisted digital channel can help
                accelerate the resolution rate of FCR in a single interaction
                journey.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                No single pane of glass or data visibility
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an organization plays around with the traditional model, it
                is more of a scenario where necessary data is amiss.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s not forget, though, HR data analytics provides visibility
                into transactional data, it is only about the final decision
                about user interactions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Data is entirely missing about what is happening before, during,
                and after each request, thus, denying the opportunity to improve
                future impacts.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Overwhelming information repositories for self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital self-service systems aim to provide information at the
                fingertips. But, it fails to make information easily navigable
                with information overload and tends to confuse and frustrate
                users.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user wants a piece of information related to the leave
                application policy, where he needs to know ‘how many paid leaves
                he can get for sickness?’
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If the related information is not to the point and it returns
                multiple repositories that give various conditions for sick
                leave applications, it does not solve the problem. Instead, a
                user is likelier to connect with the HR personnel to solve the
                issue.{" "}
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an HRIS employee self-service?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An HRIS employee self-service is an employee-HR support tool
                that allows employees and HR to accelerate the pace of service
                delivery and functional resilience through easy accessibility of
                information and the ability to auto-resolve HR-related tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As the very first quest of a Human Resource Information System
                is to provide HR-related information so that every employee can
                perform the necessary tasks without the intervention of an HR
                associate, thus reducing HR workloads and increasing efficiency
                in the tasks that otherwise need HR assistance and time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a self-service HRIS, it is easier for employees/managers to
                update their personal information to the HRIS system, fetch
                payroll information, and submit leave applications and
                reimbursements.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a modern work culture, an HRIS platform is essential to level
                up employee expectations and improve their workplace experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with ESS HRIS?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                An ESS HRIS platform provides ample opportunities to accomplish
                tasks dependent on the HR department.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Here are what your employees can do with a self-service HRIS
                platform.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="ess-use cases"
              />
              <h3 className="font-section-sub-header-small-home">
                Self-update personal information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is no longer waiting for the HR to free themselves and
                update important employee information in the HR system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a new hire may miss her attendance for several
                days, given she is not given access to the time and attendance
                portal. Without waiting for HR to update those missed days in
                the timesheet and fearing the pay cuts, it is easy to take
                control of her attendance management and update it and feel
                relaxed.{" "}
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easy access to company resources
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                An HR often needs to take time out for an induction session to
                give a clear idea about company policy and possible workplace
                culture overview. A self-service ESS makes it easy to fetch all
                the important information from the portal and reduces the time
                of delivery.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Give full control of essential information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                HRIS employee self-service system provides flexibility for
                employees to get views of their payslips, payroll, and income
                tax information. The need to wait for weeks or months is over as
                the self-service portal makes the task easy.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Leave management and approvals
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Gone are the days when a lot of paperwork happened for applying
                for leaves, which also entails TL’s signature for approval. HRIS
                removes these mundane workflows and brings speed to the whole
                process of applying for leaves and getting approvals. A quick
                and real-time notification escalates when the leave application
                is registered in the portal to help simplify approvals.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Benefits enrollment and expense management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                What if your HR personnel needs to fill out the benefits
                enrollment for your employees? There won’t be timely disbursal
                of corporate credit card payments and other employee
                reimbursements, such as internet or voice call connectivity
                costs. Instead of relying on HR to fill up the necessary
                information, let your employees do these conveniently and get
                paid on time through the HRIS portal.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Appraisal & performance management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                HRIS provides tools and workflows to track and evaluate employee
                performance with ease and greater visibility. The workflows help
                monitor feedback on the completion of the project and simplify
                evaluation and appraisal management, which also help provide
                satisfactory business outcomes with retention 一 and not
                attrition.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Notifications and reminders
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is less scope for your employees to miss important
                messages and steps to adhere to company policy. Whether for
                feedback submission on specific project experiences or taking
                charge of critical operations, HRIS notifications and reminders
                work in real-time to move the work ahead.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Round-the-clock HR information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is frustrating and irritating when HR must show up to deliver
                what your employees want. Critical information on taxes, leave
                policies, or salary slips can be just a click away on the HRIS
                portal. However, employees wait longer when HR information needs
                to be digitized, leading to manual processes.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the essential tools to maximize the use of the ESS
                platform?
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="ess-tools"
              />
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Time & Attendance </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                In the age of a hybrid work ecosystem, there is no need to pay
                for a separate time and attendance system. A cloud-native HRIS
                system easily enables time and attendance.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Knowledge base</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                All essential HR documents or data can conveniently house on the
                self-service employee portal to provide access to important HR
                information anytime. Also, the knowledge base can improve
                self-help capabilities.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Content publishing for recruitment </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                HRIS platform allows for publishing recruitment drive messages
                to be synced with several platforms such as social media
                channels and emails. Any information collected across these
                channels drops at the HR portal and accelerates recruitment
                flows.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Application Tracking System </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                In a more sophisticated HRIS platform, an application tracking
                system is an added advantage to automate recruitment. Real-time
                notifications or alerts get triggered when an application is
                received.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Live chat or agent handover </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service can solve user queries seamlessly with a
                live chat or agent handover feature. When a live chat exhausts,
                an automatic transfer to an agent works wonders for employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to reduce employee self-service abandonment?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Even though a company has an HRIS system, employees are less
                likely to use it, given the existing challenges in HR-employee
                support (refer to challenges of HR-employee support section in
                this article). However, you can apply multiple valuable ways to
                improve self-service adoption.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Promote HRIS adoption
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Send emails to communicate the benefits of the employee
                  self-service portal.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 mb-3 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Tell them it is helpful for so many things, such as updating
                  personal information or downloading salary slips without
                  knocking the HR.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create use cases for almost every event.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 pl-6 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Accept only digital leave applications and no more form
                  fill-in
                </li>
                <li className="font-section-normal-text-testimonials">
                  Facilitate application or device provisioning through the HR
                  portal only
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Walk them through the steps to use self-service for various
                  employee-manager requests.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 pl-6 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Leave requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  Tax information
                </li>
                <li className="font-section-normal-text-testimonials">
                  Feedback submission
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Build learning and development programs for employees to trick
                  them into learning the self-service portal.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Make self-service HRIS resources more meaningful.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Reduce the chances of returning search results that are answers
                to so many similar keywords or queries. Make sure a response to
                ‘how to file tax for the current Assessment Year 2023-2024 using
                company details such as a salary slip or other documents must be
                related to precisely what it means, not those resources that
                also guide tax filing for previous years.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Another way to improve self-search more meaningful is to refine
                search results for keywords or queries based on intent without
                targeting verbatim search.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a user searches for a leave request; the self-service portal
                must be programmed to detect the intent and provide results for
                ‘paid time off or PTO.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                HRIS platforms can be more convenient and flexible for users by
                integrating{" "}
                <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                  conversational AI virtual assistants such as Workativ.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ uses the power of Generative AI to harness the
                properties of large language models to make knowledge search
                more specific by tagging metadata in the database to retrieve
                information semantically correct to help improve hr-employee
                support interactions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Connect with Workativ Sales Experts to learn about opportunities
                to improve HR-Emplyoyee support with a generative AI-powered
                self-service HRIS portal.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/contact-us">Tell me more</a>
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Harness deep data analytics to personalize the employee
                experience.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To improve employee interaction with the HR management tool,
                derive data just as companies do to enhance customer experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can tap into transactional data, such as employee
                interaction data, based on the number of tickets an employee
                raises, issue-related service requests, and agent involvement.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Based on analytics, you can personalize services. Say, if an
                employee complains about a computer that frequently faces a
                ‘non-responsive’ problem, you can take care of this issue and
                offer a permanent solution.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  dashboard analytics
                </a>{" "}
                for details into logins and bot sessions, and o look for errors,
                and suggest improvement.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With ongoing improvement across HR activities, employees are
                more interested in self-service portals.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of employee self-service HRIS
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Companies can benefit their employees and HR professionals with
                myriads of features on an HRIS self-service portal.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="benefits-hris-self-service"
              />
              <ul className="font-section-sub-header-small-home float-left w-100 pl-4 margin-ul-btm blogs_ul_upd mb-1">
                <li>No intermediary support is needed </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With a self-service portal, employees become independent and
                empowered to search for HR information and accomplish many
                mundane tasks, such as getting approval for PTO without running
                after junior to senior HR associates.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Streamlined HR information for dispersed departments </li>
              </ul>

              <p class="font-section-normal-text-testimonial pl-5 line-height-18">
                In a time of hybrid work environment, when no one’s schedule is
                similar, one could struggle to find HR information easily.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Say, your HR chooses to work from home on a particular day just
                when your employee needs critical information on company
                policies for leaves. As HR is not present at the office, the
                interaction may move across several touchpoints from HR
                associates to senior HR before it can reach the Head of People.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service removes all these agonies from employee
                interaction and streamlines information to be easily accessible
                at the fingertips. ates.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Convenient employee support </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                The ability to self-update employee information is a convenient
                way to update personal information in the portal. One of the
                best use cases of this feature is to update payroll information.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                If an HR associate misses out on this critical task, a new hire
                experience could be unpleasant if that employee's salary gets
                credited late. There are more such ways to maximize the HRIS
                self-service portal.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li> Anytime, anywhere access </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                No matter where your employees are, self-service is accessible
                24x7 via cloud-native portals. Allow your employees to create
                profiles and access the system anytime and anywhere.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Faster resolution of HR issues</li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Mundane HR processes slowly subside as employee self-service
                takes over them. Tasks like employee onboarding, approval of
                leaves, and administrative tasks need hours or days to complete.
                Self-service eliminates the process chaos and speeds up the
                outcomes.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Employee data compliance and management</li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                ESS platforms keep employee data secure with end-to-end
                encryption. With fewer chances of employee data leakages,
                critical information such as any employee illness history,
                salary, or residential information remains secure; thus,
                companies can maintain data compliance and prevent workplace
                data security violations for their employees.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Cost reduction </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service HRIS removes the need to deploy multiple
                channels to address employee issues.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As a result, you would only have one integrated and centralized
                channel to address user complaints, which has less cost
                implication for your budget.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                For example, employees can ask a question in the ESS for either
                auto-resolution or agent help, which dramatically reduces
                first-contact resolution time and brings FCR costs to $0.10 per
                call from $8.01 on average when not using assisted digital
                services. ({" "}
                <a href="https://emtemp.gcom.cloud/ngw/globalassets/en/human-resources/documents/3-techniques-to-improve-selfservice-for-employees-support.pdf">
                  This cost reduction calculation is based on findings from
                  Gartner's 2019 Customer Service and Support Leaders Poll.)
                </a>
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Increased productivity and efficiency </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                HR teams can save time by not performing mundane work processes.
                On the other hand, auto-resolution of employee queries frees up
                more time for HR to focus on critical tasks.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                In addition, announcements in the HRIS system about time-offs
                and schedules are convenient to improve pay management and
                restrict no-call-no-shows chances. As a result, both HR and
                employees get time to improve their productivity and efficiency.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Streamlined recruitment and onboarding </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                There are no longer back-and-forth email interactions to ask the
                right candidate for an interview process or test assignment.
                Integrating ATS makes it easy to connect with the right
                candidate, walk her through all the necessary steps, and finally
                pull the right talent. In addition to it, the onboarding would
                be less stressful, starting from handing over essential company
                resources to the new hire to getting her to sign the agreement
                document 一 all through HR automation.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                To make the onboarding process even more convenient and faster,
                Workativ allows HR chatbot integration for tools like BambooHR,
                SAP SuccessFactors, and many other platforms to{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  facilitate new hire onboarding through app workflow automation
                  seamlessly.
                </a>
              </p>
              <ul className="font-section-sub-header-small-home  pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Improved employee-HR relation </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 mb-0 line-height-18">
                ESS provides enough benefits to streamline all critical HR
                processes, including
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5-5 mb-3 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Better management of time-off requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  Early planning of work schedules
                </li>
                <li className="font-section-normal-text-testimonials">
                  Accurate monitoring of attendance to maintain payroll (no
                  confusion due to paper-related processes that are mundane and
                  repetitive)
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As a result, the employee-HR relationship improves. It is a good
                sign for organizational growth that promises long-term employee
                retention and cost reduction.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is Workativ's advantage?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ help today’s organizations move out of critical
                business challenges related to people’s issues through HR
                support automation. ESS provides opportunities to transform the
                way employees work.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, an embedded ESS portal inside an HRIS system
                encompasses some critical modules. Employees with limited
                knowledge about the portal are less likely to use it and seek HR
                assistance more frequently, which predominantly increases the HR
                workload.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                This is where Workativ offers a seamless option for
                organizations to translate HR support into a differentiated
                employee experience. Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  conversational AI virtual assistant allows app workflow
                  automation
                </a>{" "}
                for any HRIS systems you have and seamlessly automates HR
                support.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="HR automation through chatbot integration for HRIS systems"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                With the chatbot integration for your HR software, you can
                automate many mundane tasks, such as,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Time-off request
                </li>
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Payroll management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Timesheet update
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your employees can auto-resolve these common HR issues through{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  a familiar collaborative channel like Slack or MS Teams,
                </a>{" "}
                and there is no extra effort to drive your employees to
                self-service adoption.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The bonus is Workativ VA uses a Hybrid NLU Knowledge Search,
                which improves the speed of knowledge search and accuracy by
                combining the power of conversational AI and Generative AI or
                large language models. As a result, employee productivity can
                see an incredible increase.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The current work environment is volatile and unpredictable.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It is time for organizations to focus on improving HR operations
                that promise cost reduction, talent management, workplace
                efficiency, and business success.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Leveraging an employee self-service system helps deliver
                improvements and makes employee interaction seamless.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Additionally, Workativ makes workplace support faster and
                simpler and brings a change to HR operations in the most
                frictionless manner.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Want to know more about employee self-service as a
                conversational AI chatbot for your HR platform?{" "}
                <a href="/conversational-ai-platform/demo">
                  Book a demo today.{" "}
                </a>
              </p>
            </div>
            <Faq />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Challenges of HR-employee support
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What is an HRIS employee self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. What can you do with ESS HRIS?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are the essential tools to maximize the use of the
                    ESS platform?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How to reduce employee self-service abandonment?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Benefits of employee self-service HRIS
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. What is Workativ's advantage?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Effective internal communication is integral to strengthening
                employee engagement and satisfaction. It helps build a positive
                work environment for employees wanting to work and thrive. Also,
                employee engagement is viewed as a lever to avoid the fear of
                attrition and drive employee loyalty. This is a critical aspect
                of operational resilience, where organizations must respect this
                outlook and provide a template for employees to engage and gain
                confidence in their actions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                An HRIS employee self-service system nurtures the growing demand
                of employers to make internal communication flexible and
                convenient for their employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                New findings by the University of Florida College of Journalism
                and Communications discovered that{" "}
                <a href="https://www.jou.ufl.edu/2022/12/14/study-internal-communication-can-help-strengthen-employee-engagement/">
                  internal communications strengthen employee organizational
                  engagement.
                </a>{" "}
                The study further emphasized the need to thoroughly understand
                employee attitudes to manage satisfaction and engagement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To achieve this organizational objective, an employee
                self-service ESS portal can deliver a competitive advantage for
                organizations to work with data, enabling them to personalize
                employee experience and drive real-world business values.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s explore the significance of the human resource information
                system ESS here in this article.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Challenges of HR-employee support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                High-quality manager and employee support need HR leaders to
                prioritize real-time information delivery. But to deliver more
                effective and efficient HR-employee support, it is essential to
                rise above the challenges that bog down HR efficiency.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" challenges-employee-support"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                HR leaders need digital self-service and HRIS employee
                self-service, where employees can fetch critical and simple
                forms of information. But, service delivery and functional
                resilience are not always top-notch. Some strategic differences
                ensue and increase HR challenges. What are they?
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Too many contact channels for first-contact resolution
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                First-contact resolution is always desirable across every
                touchpoint. Just as the customer service best practice aims at
                resolving issues in just one call, workplace ethics are also no
                different. This tends to force companies to implement multiple
                channels such as voice calls, SMS, social media channels, and
                emails to raise issues.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="employee-support-channels"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                This is, however, linked to making things complicated and
                impacting FCR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The 2014-2019 Gartner Effortless Experience Survey shows that
                <a href="https://emtemp.gcom.cloud/ngw/globalassets/en/human-resources/documents/3-techniques-to-improve-selfservice-for-employees-support.pdf">
                  FCR declines with the number of channels a company adds.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a request is coming from a particular channel like a live
                chat; the same request may arrive through messaging feeds on
                Twitter or other social media channels, considering a customer
                being impatient. This makes establishing the context for the
                same issue complex, thus delaying the resolution time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Contrary to this, a self-assisted digital channel can help
                accelerate the resolution rate of FCR in a single interaction
                journey.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                No single pane of glass or data visibility
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an organization plays around with the traditional model, it
                is more of a scenario where necessary data is amiss.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s not forget, though, HR data analytics provides visibility
                into transactional data, it is only about the final decision
                about user interactions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Data is entirely missing about what is happening before, during,
                and after each request, thus, denying the opportunity to improve
                future impacts.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Overwhelming information repositories for self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Digital self-service systems aim to provide information at the
                fingertips. But, it fails to make information easily navigable
                with information overload and tends to confuse and frustrate
                users.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user wants a piece of information related to the leave
                application policy, where he needs to know ‘how many paid leaves
                he can get for sickness?’
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If the related information is not to the point and it returns
                multiple repositories that give various conditions for sick
                leave applications, it does not solve the problem. Instead, a
                user is likelier to connect with the HR personnel to solve the
                issue.{" "}
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is an HRIS employee self-service?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An HRIS employee self-service is an employee-HR support tool
                that allows employees and HR to accelerate the pace of service
                delivery and functional resilience through easy accessibility of
                information and the ability to auto-resolve HR-related tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As the very first quest of a Human Resource Information System
                is to provide HR-related information so that every employee can
                perform the necessary tasks without the intervention of an HR
                associate, thus reducing HR workloads and increasing efficiency
                in the tasks that otherwise need HR assistance and time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a self-service HRIS, it is easier for employees/managers to
                update their personal information to the HRIS system, fetch
                payroll information, and submit leave applications and
                reimbursements.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a modern work culture, an HRIS platform is essential to level
                up employee expectations and improve their workplace experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What can you do with ESS HRIS?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                An ESS HRIS platform provides ample opportunities to accomplish
                tasks dependent on the HR department.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Here are what your employees can do with a self-service HRIS
                platform.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="ess-use cases"
              />
              <h3 className="font-section-sub-header-small-home">
                Self-update personal information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is no longer waiting for the HR to free themselves and
                update important employee information in the HR system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a new hire may miss her attendance for several
                days, given she is not given access to the time and attendance
                portal. Without waiting for HR to update those missed days in
                the timesheet and fearing the pay cuts, it is easy to take
                control of her attendance management and update it and feel
                relaxed.{" "}
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easy access to company resources
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                An HR often needs to take time out for an induction session to
                give a clear idea about company policy and possible workplace
                culture overview. A self-service ESS makes it easy to fetch all
                the important information from the portal and reduces the time
                of delivery.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Give full control of essential information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                HRIS employee self-service system provides flexibility for
                employees to get views of their payslips, payroll, and income
                tax information. The need to wait for weeks or months is over as
                the self-service portal makes the task easy.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Leave management and approvals
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Gone are the days when a lot of paperwork happened for applying
                for leaves, which also entails TL’s signature for approval. HRIS
                removes these mundane workflows and brings speed to the whole
                process of applying for leaves and getting approvals. A quick
                and real-time notification escalates when the leave application
                is registered in the portal to help simplify approvals.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Benefits enrollment and expense management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                What if your HR personnel needs to fill out the benefits
                enrollment for your employees? There won’t be timely disbursal
                of corporate credit card payments and other employee
                reimbursements, such as internet or voice call connectivity
                costs. Instead of relying on HR to fill up the necessary
                information, let your employees do these conveniently and get
                paid on time through the HRIS portal.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Appraisal & performance management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                HRIS provides tools and workflows to track and evaluate employee
                performance with ease and greater visibility. The workflows help
                monitor feedback on the completion of the project and simplify
                evaluation and appraisal management, which also help provide
                satisfactory business outcomes with retention 一 and not
                attrition.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Notifications and reminders
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is less scope for your employees to miss important
                messages and steps to adhere to company policy. Whether for
                feedback submission on specific project experiences or taking
                charge of critical operations, HRIS notifications and reminders
                work in real-time to move the work ahead.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Round-the-clock HR information
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is frustrating and irritating when HR must show up to deliver
                what your employees want. Critical information on taxes, leave
                policies, or salary slips can be just a click away on the HRIS
                portal. However, employees wait longer when HR information needs
                to be digitized, leading to manual processes.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the essential tools to maximize the use of the ESS
                platform?
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="ess-tools"
              />
               <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Time & Attendance </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                In the age of a hybrid work ecosystem, there is no need to pay
                for a separate time and attendance system. A cloud-native HRIS
                system easily enables time and attendance.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Knowledge base</li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                All essential HR documents or data can conveniently house on the
                self-service employee portal to provide access to important HR
                information anytime. Also, the knowledge base can improve
                self-help capabilities.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Content publishing for recruitment </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                HRIS platform allows for publishing recruitment drive messages
                to be synced with several platforms such as social media
                channels and emails. Any information collected across these
                channels drops at the HR portal and accelerates recruitment
                flows.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Application Tracking System </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                In a more sophisticated HRIS platform, an application tracking
                system is an added advantage to automate recruitment. Real-time
                notifications or alerts get triggered when an application is
                received.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Live chat or agent handover </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service can solve user queries seamlessly with a
                live chat or agent handover feature. When a live chat exhausts,
                an automatic transfer to an agent works wonders for employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to reduce employee self-service abandonment?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Even though a company has an HRIS system, employees are less
                likely to use it, given the existing challenges in HR-employee
                support (refer to challenges of HR-employee support section in
                this article). However, you can apply multiple valuable ways to
                improve self-service adoption.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Promote HRIS adoption
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Send emails to communicate the benefits of the employee
                  self-service portal.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 mb-3 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Tell them it is helpful for so many things, such as updating
                  personal information or downloading salary slips without
                  knocking the HR.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create use cases for almost every event.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 pl-6 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Accept only digital leave applications and no more form
                  fill-in
                </li>
                <li className="font-section-normal-text-testimonials">
                  Facilitate application or device provisioning through the HR
                  portal only
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Walk them through the steps to use self-service for various
                  employee-manager requests.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-3 pl-6 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Leave requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  Tax information
                </li>
                <li className="font-section-normal-text-testimonials">
                  Feedback submission
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Build learning and development programs for employees to trick
                  them into learning the self-service portal.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Make self-service HRIS resources more meaningful.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Reduce the chances of returning search results that are answers
                to so many similar keywords or queries. Make sure a response to
                ‘how to file tax for the current Assessment Year 2023-2024 using
                company details such as a salary slip or other documents must be
                related to precisely what it means, not those resources that
                also guide tax filing for previous years.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Another way to improve self-search more meaningful is to refine
                search results for keywords or queries based on intent without
                targeting verbatim search.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Say a user searches for a leave request; the self-service portal
                must be programmed to detect the intent and provide results for
                ‘paid time off or PTO.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                HRIS platforms can be more convenient and flexible for users by
                integrating{" "}
                <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                  conversational AI virtual assistants such as Workativ.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ uses the power of Generative AI to harness the
                properties of large language models to make knowledge search
                more specific by tagging metadata in the database to retrieve
                information semantically correct to help improve hr-employee
                support interactions.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Connect with Workativ Sales Experts to learn about opportunities
                to improve HR-Emplyoyee support with a generative AI-powered
                self-service HRIS portal.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/contact-us">Tell me more</a>
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Harness deep data analytics to personalize the employee
                experience.
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To improve employee interaction with the HR management tool,
                derive data just as companies do to enhance customer experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                You can tap into transactional data, such as employee
                interaction data, based on the number of tickets an employee
                raises, issue-related service requests, and agent involvement.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Based on analytics, you can personalize services. Say, if an
                employee complains about a computer that frequently faces a
                ‘non-responsive’ problem, you can take care of this issue and
                offer a permanent solution.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  dashboard analytics
                </a>{" "}
                for details into logins and bot sessions, and o look for errors,
                and suggest improvement.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With ongoing improvement across HR activities, employees are
                more interested in self-service portals.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of employee self-service HRIS
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Companies can benefit their employees and HR professionals with
                myriads of features on an HRIS self-service portal.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="benefits-hris-self-service"
              />
             <ul className="font-section-sub-header-small-home float-left w-100 pl-4 margin-ul-btm blogs_ul_upd mb-1">
                <li>No intermediary support is needed </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With a self-service portal, employees become independent and
                empowered to search for HR information and accomplish many
                mundane tasks, such as getting approval for PTO without running
                after junior to senior HR associates.
              </p>
              <ul className="font-section-sub-header-small-home float-left pl-4 w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Streamlined HR information for dispersed departments </li>
              </ul>

              <p class="font-section-normal-text-testimonial pl-5 line-height-18">
                In a time of hybrid work environment, when no one’s schedule is
                similar, one could struggle to find HR information easily.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Say, your HR chooses to work from home on a particular day just
                when your employee needs critical information on company
                policies for leaves. As HR is not present at the office, the
                interaction may move across several touchpoints from HR
                associates to senior HR before it can reach the Head of People.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service removes all these agonies from employee
                interaction and streamlines information to be easily accessible
                at the fingertips. ates.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Convenient employee support </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                The ability to self-update employee information is a convenient
                way to update personal information in the portal. One of the
                best use cases of this feature is to update payroll information.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                If an HR associate misses out on this critical task, a new hire
                experience could be unpleasant if that employee's salary gets
                credited late. There are more such ways to maximize the HRIS
                self-service portal.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li> Anytime, anywhere access </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                No matter where your employees are, self-service is accessible
                24x7 via cloud-native portals. Allow your employees to create
                profiles and access the system anytime and anywhere.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Faster resolution of HR issues</li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Mundane HR processes slowly subside as employee self-service
                takes over them. Tasks like employee onboarding, approval of
                leaves, and administrative tasks need hours or days to complete.
                Self-service eliminates the process chaos and speeds up the
                outcomes.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Employee data compliance and management</li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                ESS platforms keep employee data secure with end-to-end
                encryption. With fewer chances of employee data leakages,
                critical information such as any employee illness history,
                salary, or residential information remains secure; thus,
                companies can maintain data compliance and prevent workplace
                data security violations for their employees.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Cost reduction </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                Employee self-service HRIS removes the need to deploy multiple
                channels to address employee issues.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As a result, you would only have one integrated and centralized
                channel to address user complaints, which has less cost
                implication for your budget.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                For example, employees can ask a question in the ESS for either
                auto-resolution or agent help, which dramatically reduces
                first-contact resolution time and brings FCR costs to $0.10 per
                call from $8.01 on average when not using assisted digital
                services. ({" "}
                <a href="https://emtemp.gcom.cloud/ngw/globalassets/en/human-resources/documents/3-techniques-to-improve-selfservice-for-employees-support.pdf">
                  This cost reduction calculation is based on findings from
                  Gartner's 2019 Customer Service and Support Leaders Poll.)
                </a>
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Increased productivity and efficiency </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                HR teams can save time by not performing mundane work processes.
                On the other hand, auto-resolution of employee queries frees up
                more time for HR to focus on critical tasks.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                In addition, announcements in the HRIS system about time-offs
                and schedules are convenient to improve pay management and
                restrict no-call-no-shows chances. As a result, both HR and
                employees get time to improve their productivity and efficiency.
              </p>
              <ul className="font-section-sub-header-small-home pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Streamlined recruitment and onboarding </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                There are no longer back-and-forth email interactions to ask the
                right candidate for an interview process or test assignment.
                Integrating ATS makes it easy to connect with the right
                candidate, walk her through all the necessary steps, and finally
                pull the right talent. In addition to it, the onboarding would
                be less stressful, starting from handing over essential company
                resources to the new hire to getting her to sign the agreement
                document 一 all through HR automation.
              </p>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                To make the onboarding process even more convenient and faster,
                Workativ allows HR chatbot integration for tools like BambooHR,
                SAP SuccessFactors, and many other platforms to{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  facilitate new hire onboarding through app workflow automation
                  seamlessly.
                </a>
              </p>
              <ul className="font-section-sub-header-small-home  pl-4 float-left w-100 margin-ul-btm blogs_ul_upd mb-1">
                <li>Improved employee-HR relation </li>
              </ul>

              <p class="font-section-normal-text-testimonials pl-5 mb-0 line-height-18">
                ESS provides enough benefits to streamline all critical HR
                processes, including
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5-5 mb-3 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Better management of time-off requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  Early planning of work schedules
                </li>
                <li className="font-section-normal-text-testimonials">
                  Accurate monitoring of attendance to maintain payroll (no
                  confusion due to paper-related processes that are mundane and
                  repetitive)
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials pl-5 line-height-18">
                As a result, the employee-HR relationship improves. It is a good
                sign for organizational growth that promises long-term employee
                retention and cost reduction.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is Workativ's advantage?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ help today’s organizations move out of critical
                business challenges related to people’s issues through HR
                support automation. ESS provides opportunities to transform the
                way employees work.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However, an embedded ESS portal inside an HRIS system
                encompasses some critical modules. Employees with limited
                knowledge about the portal are less likely to use it and seek HR
                assistance more frequently, which predominantly increases the HR
                workload.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                This is where Workativ offers a seamless option for
                organizations to translate HR support into a differentiated
                employee experience. Workativ’s{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  conversational AI virtual assistant allows app workflow
                  automation
                </a>{" "}
                for any HRIS systems you have and seamlessly automates HR
                support.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="HR automation through chatbot integration for HRIS systems"
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                With the chatbot integration for your HR software, you can
                automate many mundane tasks, such as,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Time-off request
                </li>
                <li className="font-section-normal-text-testimonials">
                  Leave management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee onboarding
                </li>
                <li className="font-section-normal-text-testimonials">
                  Payroll management
                </li>
                <li className="font-section-normal-text-testimonials">
                  Timesheet update
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Your employees can auto-resolve these common HR issues through{" "}
                <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                  a familiar collaborative channel like Slack or MS Teams,
                </a>{" "}
                and there is no extra effort to drive your employees to
                self-service adoption.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The bonus is Workativ VA uses a Hybrid NLU Knowledge Search,
                which improves the speed of knowledge search and accuracy by
                combining the power of conversational AI and Generative AI or
                large language models. As a result, employee productivity can
                see an incredible increase.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The current work environment is volatile and unpredictable.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                It is time for organizations to focus on improving HR operations
                that promise cost reduction, talent management, workplace
                efficiency, and business success.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Leveraging an employee self-service system helps deliver
                improvements and makes employee interaction seamless.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Additionally, Workativ makes workplace support faster and
                simpler and brings a change to HR operations in the most
                frictionless manner.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Want to know more about employee self-service as a
                conversational AI chatbot for your HR platform?{" "}
                <a href="/conversational-ai-platform/demo">
                  Book a demo today.{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide - 2023: HR Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023: HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export function Faq() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <React.Fragment>
      <div
        className={`mob_accordian mob_accordian_faq faq_blog_upd ${
          isMobile ? "mt-3" : "mt-0"
        }`}
      >
        <section className="accordian_landing trial_accordian_landing ">
          {isMobile ? (
            <div className="container">
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                HR Help Desk Frequently Asked Questions
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </div>
          ) : (
            <>
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                FAQs about employee self-service HRIS
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </>
          )}
        </section>
      </div>
    </React.Fragment>
  );
}
export const faqData = [
  {
    header: "How to facilitate HRIS employee self-service widespread adoption?",
    paragraph:
      "Conversational AI chatbot seamlessly syncs with a familiar channel like MS Teams or Slack, making self-service faster and simpler. Workativ enables you to create app workflow automation and offers solutions for every mundane workplace query.",
    active: "active",
    uuid: "a",
  },
  {
    header: "Can third-party virtual assistants complement HRIS ESS?",
    paragraph:
      "Yes, a third-party virtual assistant can interact with your existing HR platform and allow you to create app workflows for HR use cases. Since a chatbot syncs with a collaboration channel, you can access self-service through a mobile device.   ",
  },
  {
    header: "How to make ESS HRIS more responsive and optimized?",
    paragraph:
      "Conversational AI is a convenient way to make ESS HRIS more responsive and optimized. However, Workativ uses both conversational AI and generative AI to deliver accurate responses for almost every NLP query. ",
  },
  {
    header: "Is there a live chat in a third-party chatbot for HRIS ESS?",
    paragraph:
      "Yes, building your live chat function for HR support is easier.<a href=`https://workativ.com/conversational-ai-platform/agent-handover-chatbot`> Workativ’s agent handover chatbot</a> quickly carries forward the conversation without repeating the case history, yet finds a solution. ",
  },
];
